<template>
  <div class="navbar-container bg-white navbar--not-logged">
    <b-container>
      <b-navbar toggleable="md" class="main-navbar bg-white px-0">
        <div class="logo-top-container-new" v-on:click.alt="viewVersion">
          <router-link to="/" class="navbar-brand m-0 p-0 d-none d-md-inline-block">
            <img :src="require('../../assets/img/logo-hor-MFSOLVENCY.png')" class="logo-top p-0">
          </router-link>
          <router-link to="/" class="navbar-brand m-0 p-0 d-md-none">
            <img :src="require('../../assets/img/logo_badge.png')" class="logo-top p-0">
          </router-link>
        </div>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
<!--          <b-navbar-nav>-->
<!--            <b-link to="/registrati" class="nav-link login">REGISTRATI</b-link>-->
<!--            <b-link to="/accedi" class="nav-link login">ACCEDI</b-link>-->
<!--          </b-navbar-nav>-->
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>

import { extractErrorMessage } from '@/utils/validators';
import { toastInformation } from '@/utils/toast';
import { version } from '@/utils/utilities';

export default {
  name: 'HeaderEmpty',
  components: {
  },
  data() {
    return {
      submitted: false,
      message: null,
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters['auth/isLogged'];
    },
    currentName() {
      return this.$store.getters['auth/currentName'];
    },
  },
  methods: {
    submitLogout() {
      this.$confirm(
        {
          message: "Stai per abbandonare l'area riservata, confermi?",
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$store.dispatch('auth/logout').then(() => {
                if (this.$route.path !== '/') this.$router.replace('/');
                this.submitted = false;
              },
              (error) => {
                this.message = extractErrorMessage(error);
                this.submitted = false;
              });
            }
          },
        },
      );
    },
    viewVersion() {
      console.log('viewVersion');
      toastInformation(`Version v. ${version}`);
    },
  },
};
</script>
